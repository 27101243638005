<template>
  <div class="page-help">
    <div class="section-qa section-box">
      <div class="content-box">
        <h1 class="title">{{ title }}</h1>
        <ul class="qa-list">
          <li v-for="(item, index) in list" :key="index">
            <p class="text-q" v-html="item.q"></p>
            <p
              class="text-a"
              v-for="(itema, indexa) in item.a"
              :key="indexa"
              v-html="itema"
              @click="onBtn"
            ></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import helpdata from "../util/helpdata";
import { goPageByName } from "@/util/link";
export default {
  name: "HelpView",
  data() {
    return {
      title: "",
      list: [],
      type: "faq",
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    console.log("helpdata", helpdata);
    this.type = this.$route.query.type || this.type;
    this.title = helpdata[this.type].title;
    this.list = helpdata[this.type].list;
  },
  methods: {
    onBtn(e) {
      if (e.target.id == "store") {
        goPageByName({
          name: "store",
        });
      }
      if (e.target.id == "logon") {
        goPageByName({
          name: "logon",
        });
      }
    },
  },
};
</script>
<style>
.page-help #store,
.page-help #logon {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: #ff521f;
}
</style>
<style lang="scss">
.page-help {
  width: 100%;
  background: #f4f5f6;
  .section-qa {
    width: 100%;
    .content-box {
      width: 100%;
      padding: 0.48rem 0.4rem 0.3rem;
      box-sizing: border-box;
    }
    .title {
      font-size: 0.4rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.55rem;
    }
    .text {
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 0.36rem;
      margin-top: 0.35rem;
      text-align: left;
    }
    .qa-list {
      width: 100%;
      text-align: left;
      margin: 0.35rem auto 0;
      li {
        margin-bottom: 0.4rem;
        .text-q {
          font-size: 0.32rem;
          font-family: AlibabaPuHuiTiH;
          color: #000000;
          line-height: 0.44rem;
        }
        .text-a {
          font-size: 0.28rem;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 0.36rem;
          margin-top: 0.16rem;
          text-indent: 0.56rem;
        }
      }
    }
  }
}
</style>
