<template>
  <div class="page-member-ship">
    <!-- banner -->
    <div class="section-banner section-box" v-if="type !== 'success'">
      <div class="content-box">
        <div>
          <h1 class="title">Hi {{ firstName }} {{ lastName }}!</h1>
          <div class="img-box">
            <img src="../assets/img/membership/icon-idtips-w.png" alt="" />
          </div>
          <p class="text-info"><span>Member ID:</span>{{ cardNo }}</p>
          <p class="text-info"><span>Lucky Points:</span>{{ points }}</p>
        </div>
      </div>
    </div>
    <!-- success -->
    <div class="section-success section-box" v-else>
      <div class="content-box">
        <div class="success-img img-box">
          <img src="../assets/img/membership/icon-success.png" alt="" />
        </div>
        <h1 class="title">
          Congrats, {{ firstName }} {{ lastName }}!<br />You have successfully
          registered!
        </h1>
        <div class="tips-img img-box">
          <img src="../assets/img/membership/icon-idtips-b.png" alt="" />
        </div>
        <p class="text-info"><span>Member ID:</span>{{ cardNo }}</p>
        <p class="text-info"><span>Lucky Points:</span>{{ points }}</p>
      </div>
    </div>
    <!-- info -->
    <div class="section-info section-box">
      <div class="content-box">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="Redeem Prizes" name="1">
            <p class="nav" @click="goPageByName('redeemprizes')">
              I’m winner! Redeem now >
            </p>
            <p class="nav" @click="goPageByName('redeemhistory')">
              Redeem History >
            </p>
          </van-collapse-item>
          <van-collapse-item title="Lucky Points" name="2">
            <p class="nav" @click="goPageByName('points')">View Details ></p>
          </van-collapse-item>
          <van-collapse-item title="Account Settings" name="3">
            <p class="nav" @click="goPageByName('details')">My Details ></p>
            <p class="nav" @click="goPageByName('address')">Address Book ></p>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <!-- wish -->
    <div class="section-wish section-box">
      <div class="content-box">
        <div class="icon-img img-box">
          <img src="../assets/img/membership/img-xhr.png" alt="" />
        </div>
        <h2 class="text-q">
          What Prizes do you want to see<br />in Lucky Box? Let us know!
        </h2>
        <button @click="onBtWish" class="bt-wish">I WISH FOR...</button>
      </div>
    </div>
    <!-- dialog -->
    <LkDialog ref="LkDialog"></LkDialog>
    <LkDialogForm
      ref="LkDialogForm"
      @submit="onSubmit"
      @close="onClose"
    ></LkDialogForm>
  </div>
</template>
<script>
import LkDialog from "@/components/LkDialog.vue";
import LkDialogForm from "@/components/LkDialogForm.vue";
import { getInfo } from "@/api/user";
import { goPageByName } from "@/util/link";
import { submitText } from "@/api/submission";
export default {
  name: "MemberShipView",
  data() {
    return {
      activeNames: [],
      desc: {},
      type: "",
      userId: "",
      firstName: "",
      lastName: "",
      points: "",
      cardNo: "",
    };
  },
  components: {
    LkDialog,
    LkDialogForm,
  },
  mounted() {
    this.type = this.$route.query.type;
    this.userId = localStorage.getItem("userId");
    this.cardNo = localStorage.getItem("cardNo");
    console.log(this.$route.query);
    this.getInfo();
  },
  methods: {
    onBtWish() {
      this.$refs.LkDialogForm.show({
        title: `What Prizes do you want to see in Lucky Box?Let us know!`,
        bt: "SUBMIT",
        type: "wish",
        desc: this.desc.wish,
      });
    },
    async onSubmit(obj) {
      console.log("obj", obj);
      if (!obj.desc) {
        this.$toast("Please write some words before submitting.");
        return;
      }
      const res = await submitText({
        content: obj.desc,
        type: 1,
      });
      if (res.code === 0) {
        this.$refs.LkDialogForm.close();
        this.$refs.LkDialog.show({
          title: `Thanks for your share!`,
          text: "Your information has been submitted successfully.",
          bt: "OK",
        });
      } else {
        this.$toast(res.msg);
      }
    },
    onClose(obj) {
      console.log(obj);
      this.desc[obj.type] = obj.desc;
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        this.firstName = res.data.firstName;
        this.lastName = res.data.lastName;
        this.points = res.data.points;
      } else {
        this.$toast(res.msg);
      }
    },
    goPageByName(name) {
      goPageByName({ name });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-member-ship {
  width: 100%;
  background: #f4f5f6;
  .section-banner {
    width: 100%;
    height: 4.4rem;
    position: relative;
    background: url("../assets/img/membership/banner.png") no-repeat center;
    background-size: 100%;
    .content-box {
      position: relative;
      height: 4.4rem;
      padding-top: 0.6rem;
      box-sizing: border-box;
    }
    .title {
      font-size: 0.56rem;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
      line-height: 0.77rem;
      margin-bottom: 0.2rem;
    }
    .img-box {
      width: 4.42rem;
      height: 0.6rem;
      margin: 0 auto 0.09rem;
    }
    .text-info {
      font-size: 0.26rem;
      font-family: AlibabaPuHuiTiM;
      color: #ffffff;
      line-height: 0.35rem;
      margin-bottom: 0.1rem;
      text-align: left;
      span {
        // width: 4rem;
        margin-left: 2.3rem;
        text-align: left;
        display: inline-block;
      }
    }
  }
  .section-success {
    padding: 0.6rem 0 0.5rem;
    position: relative;
    &::after {
      content: "";
      width: 6.9rem;
      height: 0;
      border-bottom: 1px dashed #a7a7a8;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
    .content-box {
      position: relative;
    }
    .success-img {
      width: 4rem;
      height: 2.4rem;
      margin: 0 auto 0.5rem;
    }
    .title {
      font-size: 0.36rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.5rem;
      word-spacing: -7px;
    }
    .tips-img {
      width: 4.44rem;
      height: 0.68rem;
      margin: 0.19rem auto 0.08rem;
    }
    .text-info {
      font-size: 0.26rem;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      line-height: 0.35rem;
      margin-top: 0.1rem;
      text-align: left;
      span {
        // width: 4rem;
        margin-left: 2.3rem;
        text-align: left;
        display: inline-block;
      }
    }
  }
  .section-info {
    .content-box::v-deep {
      padding: 0 0.3rem 0.5rem;
      box-sizing: border-box;
      .van-cell {
        background: #f4f5f6;
        padding: 0;
        text-align: left;
      }
      .van-cell__title {
        font-size: 0.36rem;
        font-family: AlibabaPuHuiTiB;
        color: #000;
        line-height: 0.5rem;
      }
      .van-collapse-item__content {
        background: #f4f5f6;
        padding: 0;
      }
      [class*="van-hairline"]::after {
        display: none;
      }
      .van-collapse-item {
        border-bottom: 1px solid rgba($color: #cbcbcb, $alpha: 0.5);
        padding: 0.5rem 0;
        &:last-child {
          border-bottom: 0;
        }
      }
      .van-collapse-item--border::after {
        display: none;
      }
      .van-cell__right-icon {
        width: 0.48rem;
        height: 0.48rem;
        background: url("../assets/img/membership/icon-+.png") no-repeat center;
        background-size: 100%;
      }
      .van-collapse-item__title--expanded .van-cell__right-icon {
        width: 0.48rem;
        height: 0.48rem;
        background: url("../assets/img/membership/icon--.png") no-repeat center;
        background-size: 100%;
      }
      .van-icon-arrow:before {
        display: none;
      }
      .nav {
        text-align: left;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.38rem;
        margin-top: 0.3rem;
      }
    }
  }
  .section-wish {
    padding: 0 0.3rem 1rem;
    box-sizing: border-box;
    .content-box {
      width: 100%;
      height: 6.36rem;
      background: url("../assets/img/membership/bg-wish.jpg") no-repeat center;
      background-size: 100%;
      box-sizing: border-box;
      text-align: center;
    }
    .icon-img {
      width: 2.49rem;
      height: 2.83rem;
      position: absolute;
      top: -0.24rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
    .text-q {
      font-size: 0.36rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.5rem;
      padding-top: 3.18rem;
    }
    .bt-wish {
      width: 2.8rem;
      line-height: 0.88rem;
      background: #f4cc25;
      border-radius: 8px;
      border: 2px solid #000000;
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      box-sizing: border-box;
      margin-top: 0.5rem;
    }
  }
}
</style>
