<template>
  <div class="page-news">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- news -->
    <div class="section-news section-box">
      <div class="content-box">
        <ul>
          <li
            class="img-box"
            v-for="(item, index) in newsList"
            :key="index"
            @click="onGo(item)"
          >
            <img :src="item.image" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <!-- share -->
    <div class="section-share section-box">
      <div class="content-box">
        <h1 class="title">See Our Partners Share...</h1>
        <ul class="share-list">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in partnersShare"
                :key="index"
              >
                <li class="share-item img-box">
                  <img src="../assets/img/news/bg-user.png" alt="" />
                  <img
                    :src="item.image"
                    alt=""
                    class="ins-img"
                    :data-url="item.url"
                  />
                </li>
              </div>
            </div>
          </div>
        </ul>
        <div class="bt-box">
          <div class="bt-left" @click="onPrev"></div>
          <div class="bt-right" @click="onNext"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import { getMaterial } from "@/api/submission";

export default {
  name: "NewsView",
  data() {
    return {
      autoplay: false,
      mySwiper: {},
      newsList: [],
      partnersShare: [],
    };
  },
  components: {},
  mounted() {
    this.getMaterial();
    setTimeout(() => {
      this.mySwiper = new Swiper(".swiper-container", {
        autoplay: true,
        loop: true, // 循环模式选项
        slidesPerView: "auto",
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
        observer: true,
        observerParents: true,
        on: {
          click: function (sw) {
            console.log(1);
            const url = sw.target.getAttribute("data-url");
            if (url) {
              window.location.href = url;
            }
          },
        },
      });
    }, 1000);
  },
  methods: {
    onPrev() {
      this.mySwiper.slidePrev();
    },
    onNext() {
      this.mySwiper.slideNext();
    },
    async getMaterial() {
      const res = await getMaterial({
        tags: "mNews,partnersShare",
      });
      console.log("res", res);
      this.newsList = res.data.mNews;
      this.partnersShare = res.data.partnersShare;
    },
    onGo(item) {
      console.log(item.image);
      if (item.url) {
        window.location.href = item.url;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-news {
  .section-banner {
    width: 100%;
    height: 3rem;
    background: url("../assets/img/news/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-news {
    background: #f4f5f6;
    &:after {
      width: 100%;
      height: 0.17rem;
      content: "";
      background: url("../assets/img/news/bg-news-line.png") no-repeat center;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .content-box {
      padding: 0.5rem 0 0.5rem;
    }
    ul {
      li {
        width: 6.9rem;
        height: 2.6rem;
        margin: 0 auto 0.2rem;
      }
    }
  }
  .section-share {
    background: #ffd62a;
    &:after {
      width: 100%;
      height: 0.16rem;
      content: "";
      background: url("../assets/img/home/bg-winner-bottom.png") no-repeat
        center;
      background-size: 100%;
      position: absolute;
      left: 0;
      bottom: -1px;
    }
    .content-box {
      width: 100%;
      padding: 0.55rem 0 0.65rem;
    }
    .title {
      font-size: 0.44rem;
      font-family: AlibabaPuHuiTiB;
      color: #231815;
      line-height: 0.6rem;
    }
    .share-list::v-deep {
      margin-top: 0.36rem;
      white-space: nowrap;
      overflow: auto;
      .share-item {
        display: inline-block;
        width: 4.08rem;
        height: 6.47rem;
        margin-right: 0.32rem;
        position: relative;
        .ins-img {
          width: 3.8rem;
          height: 6.02rem;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
      .swiper-slide {
        width: 4.08rem !important;
        height: 6.47rem !important;
        margin-right: 0.32rem;
      }
    }
    .bt-box {
      width: 100%;
      margin: 0.49rem auto 0;
      text-align: right;
      .bt-left,
      .bt-right {
        display: inline-block;
        margin-left: 0.25rem;
        width: 0.72rem;
        height: 0.6rem;
      }
      .bt-right {
        margin-right: 0.5rem;
      }
      .bt-left {
        background: url("../assets/img/home/icon-winner-l.png") no-repeat center;
        background-size: 100%;
        &:active {
          background: url("../assets/img/home/icon-winner-l-a.png") no-repeat
            center bottom;
          background-size: 100% 0.53rem;
        }
      }
      .bt-right {
        background: url("../assets/img/home/icon-winner-r.png") no-repeat center;
        background-size: 100%;
        &:active {
          background: url("../assets/img/home/icon-winner-r-a.png") no-repeat
            center bottom;
          background-size: 100% 0.53rem;
        }
      }
    }
  }
}
</style>
