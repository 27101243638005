const FirstName = [
  {
    required: true,
    pattern: /^[A-Za-z]{2,}$/,
    message: "Please enter the correct First Name",
  },
];

const LastName = [
  {
    required: true,
    pattern: /^[A-Za-z]{2,}$/,
    message: "Please enter the correct Last Name",
  },
];

const Email = [
  {
    required: true,
    pattern: /@.*/,
    message: "Please enter the correct Email Address",
  },
];

const Password = [
  {
    required: true,
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
    message:
      "password must be longer than six digits, cap sensitive and contain a combination of letters and numbers",
  },
];

const Phone = [
  {
    required: true,
    pattern: /^\d{9}$/,
    message: "Please enter a valid phone number (only digits allowed)",
  },
];

const AddressLine1 = [
  {
    required: true,
    pattern: /^.{10,}$/,
    message: "The address is too short, please add)",
  },
  {
    required: true,
    pattern: /[a-zA-Z]/g,
    message: "Please enter the correct Address",
  },
];

const PostCode = [
  {
    required: true,
    pattern: /^\d{4}$/,
    message: "Please enter a valid postcode (only digits allowed)",
  },
];

export default {
  FirstName,
  LastName,
  Email,
  Password,
  Phone,
  AddressLine1,
  PostCode,
};
