import axios from "axios";
import { Toast } from "vant";

// let Loading_Request_Count = 0; // loading统计次数
const service = axios.create({
  timeout: 20000, // request timeout
  withCredentials: true, // 跨域请求时发送Cookie
});
// const toast = Toast.loading({
//   duration: 0, // 持续展示 toast
//   forbidClick: true,
//   message: "倒计时 3 秒",
// });

service.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    if (!config.isNoNeedLoad) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
    }
    const authToken = localStorage.getItem("authToken");
    config.headers["auth-token"] = authToken;
    // if (process.env.NODE_ENV == 'development') config.headers.isDev = 'dev';
    return config;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => Promise.reject(error)
);

async function request({ url, method = "get", ...option }) {
  const param = {
    url,
    method,
    ...option,
  };

  try {
    return await new Promise((resolve, reject) => {
      service.defaults.url = url;
      service(param)
        .then((res) => {
          if (!param.isNoNeedLoad) {
            Toast.clear();
          }
          resolve(
            Object.assign(
              {
                res,
                ...res.data,
              },
              {
                tokenStatus: localStorage.getItem("token") ? true : false,
              }
            )
          );
        })
        .catch((err) => {
          if (!param.isNoNeedLoad) {
            Toast.clear();
          }
          console.log(err);
          setTimeout(() => {
            Toast(err.message);
          }, 300);
          if (err.message != "cancel") {
            reject(err);
          }
        });
    });
  } catch (error) {
    if (!param.isNoNeedLoad) {
      Toast.clear();
    }
    return await Promise.reject(error);
  }
}

export { request };
