<template>
  <van-popup class="lk-dialog" v-model="dialogVisible" closeable>
    <h1 class="title">{{ title }}</h1>
    <p class="text">{{ text }}</p>
    <button @click="submit" class="bt">{{ bt }}</button>
  </van-popup>
</template>

<script>
export default {
  name: "LkAlert",
  components: {},
  data() {
    return {
      dialogVisible: false,
      title: "Thanks for your share!",
      text: "Your information has been submitted successfully.",
      bt: "submit",
    };
  },
  methods: {
    show({ title, text, bt }) {
      this.title = title || this.title;
      this.text = text || this.text;
      this.bt = bt || this.bt;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    submit() {
      this.close();
    },
    cancel() {
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.lk-dialog::v-deep {
  width: 6rem;
  box-sizing: border-box;
  text-align: center;
  padding: 0.78rem 0.5rem 0.77rem;
  .title {
    font-size: 0.36rem;
    font-family: AlibabaPuHuiTiH;
    color: #000000;
    line-height: 0.5rem;
    margin-bottom: 0.3rem;
  }
  .text {
    font-size: 0.28rem;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 0.38rem;
    margin-bottom: 0.5rem;
  }
  .bt {
    width: 2.8rem;
    line-height: 0.88rem;
    background: #f4cc25;
    border-radius: 0.08rem;
    border: 1px solid #000000;
    font-size: 0.28rem;
    font-family: AlibabaPuHuiTiM;
    color: #000000;
  }
  .van-icon-cross:before {
    display: none;
  }
  .van-popup__close-icon {
    width: 0.58rem;
    height: 0.58rem;
    background: url("../assets/img/head/icon-x.png") no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
</style>
