<template>
  <div class="page-redeem-history">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- list -->
    <div class="section-list section-box">
      <div class="content-box">
        <template v-if="list.length">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <div class="header">
              <span class="order-num">Redeem code： {{ item.redeemCode }}</span>
              <span v-if="item.status == 2" class="status">Delivered</span>
              <span v-if="item.status == 0" class="status">In Progress</span>
              <span v-if="item.status == 1" class="status"
                >Attention<br />Required</span
              >
            </div>
            <p class="order-info">Product：{{ item.prizeName }}</p>
            <div class="img-box">
              <img :src="item.image" alt="" />
            </div>
            <p class="order-info">Price: {{ item.price }}</p>
            <p class="order-info">Phone number: {{ item.phone }}</p>
            <p class="order-info">
              Shipping address:<br />{{ item.addressLine1 }}
              {{ item.addressLine2 }}，{{ item.suburb }}, {{ item.state }}
              {{ item.zipCode }}
            </p>
            <p class="order-info">Redeem time: {{ item.createdAt }}</p>
            <p class="order-info" v-if="item.expressNumber">
              Tracking number:{{ item.expressNumber }}
            </p>
            <p class="order-info" v-if="item.comment">
              comment:{{ item.comment }}
            </p>
            <div class="bt-box" v-if="item.status == 2">
              <button @click="goPost(item)" class="sb-bt">
                MORE TRACKING DETAILS
              </button>
            </div>
            <div class="bt-box" v-if="item.status == 1">
              <button @click="goPageByName(item)" class="sb-bt">UPDATE</button>
            </div>
          </div>
        </template>
        <div class="points-empty" v-else>
          <div class="img-box">
            <img src="../assets/img/points/icon-empty.png" alt="" />
          </div>
          <p>It’s empty here :-(</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRedeemList } from "@/api/redeem";
import { goPageByName } from "@/util/link";
export default {
  name: "RedeemHistoryView",
  data() {
    return {
      list: [],
      postUrl: "https://auspost.com.au/mypost/track/#/details/",
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    this.getRedeemList();
  },
  methods: {
    async getRedeemList() {
      const res = await getRedeemList();
      if (res.code === 0) {
        this.list = res.data.list;
      }
    },
    goPageByName(item) {
      goPageByName({
        name: "redeemprizes",
        query: {
          redeemCode: item.redeemCode,
        },
      });
    },
    goPost(item) {
      console.log(item);
      window.location.href = this.postUrl + item.expressNumber;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-redeem-history {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/redeemhistory/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-list {
    background: #f4f5f6;
    .content-box {
      width: 100%;
      margin: 0 auto;
      padding: 0.5rem 0.3rem 0.7rem;
      box-sizing: border-box;
    }
    .list-item {
      width: 100%;
      box-sizing: border-box;
      background: #fff;
      padding: 0.3rem 0.3rem 0.5rem;
      margin-bottom: 0.3rem;
      .header {
        height: 0.38rem;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.3rem;
        border-bottom: 1px dashed #a7a7a8;
        margin-bottom: 0.3rem;
        .order-num {
          font-size: 0.28rem;
          font-family: AlibabaPuHuiTiB;
          color: #000000;
          line-height: 0.38rem;
        }
        .status {
          font-size: 0.28rem;
          font-family: AlibabaPuHuiTiB;
          color: #ff0000;
          line-height: 0.38rem;
        }
      }
      .img-box {
        width: 6.3rem;
        height: 6.3rem;
        background: #e9e9e9;
        border-radius: 5px;
        border: 1px solid #979797;
        overflow: hidden;
        margin: 0.3rem auto;
      }
      .order-info {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.42rem;
        text-align: left;
        margin-bottom: 0.2rem;
      }
    }
    .bt-box {
      margin-top: 0.5rem;
    }
    .sb-bt {
      width: 6.3rem;
      line-height: 0.88rem;
      background: #f4cc25;
      box-sizing: border-box;
      border-radius: 8px;
      border: 2px solid #000000;
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      text-align: center;
    }
    .points-empty {
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;
      text-align: center;
      .img-box {
        width: 1.54rem;
        height: 1.26rem;
        margin: 0 auto;
      }
      p {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.34rem;
        margin-top: 0.27rem;
      }
    }
  }
}
</style>
