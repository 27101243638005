<template>
  <div class="section-head">
    <div class="content-box">
      <div class="icon-nav" @click="show = true"></div>
      <div class="logo" @click="goPageByName('home')"></div>
      <div class="right-box">
        <div class="redeem" @click="onRedeem">
          <img src="../assets/img/head/icon-gift.png" alt="" />
        </div>
        <div class="login" @click="onLogin">
          <img src="../assets/img/head/icon-user.png" alt="" />
        </div>
      </div>
    </div>
    <div class="kong"></div>
    <van-popup
      v-model="show"
      position="left"
      :close-on-click-overlay="closeonclickoverlay"
      :closeable="closeable"
    >
      <div class="popup-conten">
        <nav>
          <router-link
            @click.native="onNav"
            :class="{ active: item.isActive }"
            v-for="item in navData"
            :key="item.name"
            class="link"
            :to="item.path"
            >{{ item.name }}</router-link
          >
        </nav>
        <i class="icon-line"></i>
        <p v-if="isLogin" @click="onLogOut" class="text-logout">Log Out</p>
        <p v-else @click="goPageByName('logon')" class="text-logout">Log In</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { goPageByName } from "@/util/link";
import store from "@/store";
export default {
  name: "PageHead",
  data() {
    return {
      show: false,
      closeonclickoverlay: true,
      closeable: true,
    };
  },
  computed: {
    ...mapState(["navData", "isLogin"]),
  },
  mounted() {},
  methods: {
    onLogin() {
      if (this.isLogin) {
        goPageByName({ name: "membership" });
      } else {
        goPageByName({ name: "logon" });
      }
    },
    onRedeem() {
      if (this.isLogin) {
        goPageByName({ name: "redeemprizes" });
      } else {
        goPageByName({ name: "logon" });
      }
    },
    onNav() {
      this.show = false;
    },
    onLogOut() {
      this.show = false;
      store.commit("setLogOut");
      goPageByName({
        name: "logon",
      });
    },
    goPageByName(name) {
      this.show = false;
      goPageByName({
        name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-head::v-deep {
  background: #231815;
  .kong {
    height: 0.88rem;
  }
  .content-box {
    width: 100%;
    height: 0.88rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: #231815;
    padding: 0 0.3rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-nav {
      width: 0.48rem;
      height: 0.48rem;
      background: url("../assets/img/head/icon-nav.png") no-repeat center;
      background-size: 100%;
    }
    .logo {
      width: 2.05rem;
      height: 0.55rem;
      background: url("../assets/img/head/icon-luckybox.png") no-repeat center;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    nav {
      flex: 1;
      .link {
        line-height: 42px;
        font-size: 16px;
        color: #666666;
        margin-left: 32px;
        &.active,
        &:hover {
          color: #f4cc25;
        }
      }
    }
    .right-box {
      display: flex;
      align-items: center;
      .redeem,
      .login {
        display: flex;
        img {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
      .redeem {
        margin-right: 0.28rem;
      }
    }
  }
  .popup-conten {
    position: relative;
    font-size: 0;
    .close {
      width: 0.58rem;
      height: 0.58rem;
      position: absolute;
      top: -0.73rem;
      right: -0.2rem;
    }
    .link {
      display: block;
      line-height: 0.74rem;
      font-size: 0.32rem;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
    }
    .icon-line {
      width: 100%;
      display: inline-block;
      border-bottom: 1px dashed rgba($color: #a7a7a8, $alpha: 0.5);
      margin-top: 0.35rem;
    }
    .text-logout {
      font-size: 0.32rem;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 0.44rem;
      margin-top: 0.5rem;
    }
  }
  .van-popup {
    width: 6.5rem;
    height: 100%;
    padding: 1.03rem 0.5rem 0;
    box-sizing: border-box;
  }
  .van-popup__close-icon--top-right {
    width: 0.58rem;
    height: 0.58rem;
    background: url("../assets/img/head/icon-x.png") no-repeat center;
    background-size: 100%;
    top: 0.3rem;
    right: 0.3rem;
  }
  .van-icon-cross:before {
    display: none;
  }
}
</style>
