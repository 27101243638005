<template>
  <div class="page-details">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <van-form @submit="onSubmit" class="form-box">
          <van-field
            v-model="form.firstName"
            class="part"
            placeholder="First Name*"
            :rules="RULES.FirstName"
          />
          <van-field
            v-model="form.lastName"
            class="part"
            placeholder="Last Name*"
            :rules="RULES.LastName"
          />
          <p class="label">Gender</p>
          <van-radio-group v-model="form.gender" direction="horizontal">
            <van-radio :name="1">Male</van-radio>
            <van-radio :name="2">Female</van-radio>
            <van-radio :name="3">Rather Not to Say</van-radio>
          </van-radio-group>
          <van-field
            v-model="form.email"
            placeholder="Email Address*"
            :rules="RULES.Email"
          />
          <p class="label">Birthday</p>
          <p
            @click="dateshow = true"
            class="click-bt"
            :class="{ choose: isChooseDate }"
          >
            {{ form.birthday }}<i></i>
          </p>
          <div class="phone-box">
            <van-field
              v-model="form.phone"
              placeholder="Phone Number*"
              :rules="RULES.Phone"
            >
            </van-field>
            <i class="select">+61</i>
          </div>
          <van-button class="sb-bt" round block type="info" native-type="submit"
            >CONFIRM</van-button
          >
        </van-form>
      </div>
    </div>
    <!-- 日期 -->
    <van-popup class="popup-date" v-model="dateshow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="Birthday"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateConfirm"
        @cancel="dateshow = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { saveUserInfo, getInfo } from "@/api/user";
// import { goReplaceByName } from "@/util/link";
import RULES from "@/util/rules";
export default {
  name: "DetailsView",
  data() {
    return {
      minDate: new Date(1980, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(2000, 0, 1),
      dateshow: false,
      isChooseDate: false,
      form: {
        firstName: "",
        lastName: "",
        birthday: "2000-01-01",
        gender: "3",
        phone: "",
        email: "",
      },
      phonePattern: /\d{9}/,
      emailPattern: /@.*\.com/,
      RULES,
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    this.getInfo();
  },
  methods: {
    async onSubmit() {
      const res = await saveUserInfo({
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        gender: this.form.gender,
        birthday: this.form.birthday,
        phone: this.form.phone,
      });
      if (res.code === 0) {
        this.$toast("success");
        // goReplaceByName({ name: "address" });
      } else {
        this.$toast(res.msg);
      }
    },
    onDateConfirm(value) {
      const y = value.getFullYear();
      const m = value.getMonth() + 1;
      const d = value.getDate();
      const birthday = `${y}-${this.changeDate(m)}-${this.changeDate(d)}`;
      this.form.birthday = birthday;
      this.isChooseDate = true;
      this.dateshow = false;
    },
    // 日期格式
    changeDate(value) {
      if (value <= 9) {
        return "0" + value;
      }
      return value;
    },
    // 获取个人信息
    async getInfo() {
      const res = await getInfo();
      console.log(res);
      if (res.code === 0) {
        this.form = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-details {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/details/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 100%;
      padding: 0.5rem 0.3rem 1rem;
      box-sizing: border-box;
    }
    .form-box::v-deep {
      .van-cell {
        padding: 0;
        box-sizing: border-box;
        // height: 0.88rem;
        margin-bottom: 0.5rem;
        overflow: visible;
        background-color: #f4f5f6;
        vertical-align: top;
      }
      .part {
        width: 3.35rem;
        display: inline-block;
        &:first-child {
          margin-right: 0.2rem;
        }
      }
      .van-field--error .van-field__control {
        border: 1px solid #ff0000;
      }
      .van-field__control {
        height: 0.88rem;
        padding: 0.3rem 0 0.3rem 0.3rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        box-sizing: border-box;
        border-radius: 0.08rem;
        border: 1px solid #cdcdcd;
      }
      .van-field__error-message {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 0.26rem;
        margin-top: 0.2rem;
        padding-left: 0.3rem;
      }
      .van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: #666;
      }
      .label {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiB;
        color: #000000;
        line-height: 0.38rem;
        text-align: left;
        margin-bottom: 0.3rem;
      }
      .van-radio-group--horizontal {
        margin-bottom: 0.5rem;
        justify-content: space-between;
      }
      .van-radio__label {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.38rem;
        margin-left: 0.2rem;
      }
      .van-radio {
        margin-right: 0;
      }
      .van-icon:before {
        display: none;
      }
      .van-icon {
        width: 0.34rem;
        height: 0.34rem;
        background: url("../assets/img/address/icon-k.png") no-repeat center;
        background-size: 100%;
        border: none;
      }
      .van-radio__icon--checked .van-icon {
        width: 0.34rem;
        height: 0.34rem;
        background: url("../assets/img/address/icon-x.png") no-repeat center;
        background-size: 100%;
      }
      .click-bt {
        width: 100%;
        height: 0.9rem;
        border-radius: 8px;
        border: 1px solid #cdcdcd;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        padding: 0.25rem 0 0.25rem 0.3rem;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        margin-bottom: 0.5rem;
        &.choose {
          color: #000;
        }
        i {
          width: 0.22rem;
          height: 0.12rem;
          background: url("../assets/img/address/icon-san.png") no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 0.31rem;
          transform: translate3d(0, -50%, 0);
        }
      }
      .phone-box {
        position: relative;
        .van-cell {
          padding-left: 1rem;
          // padding: 0.3rem 0 0.3rem 0.3rem;
          height: 0.88rem;
          border-radius: 0.08rem;
          border: 1px solid #cdcdcd;
        }
        .van-field--error {
          margin-bottom: 1.22rem;
          border: 1px solid #ff0000;
        }
        .van-field__control {
          padding-left: 0;
          border: 0;
        }
      }
      .select {
        width: 0.5rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000;
        line-height: 0.38rem;
        position: absolute;
        left: 0.3rem;
        top: 0.26rem;
      }
      .sb-bt {
        width: 6.9rem;
        height: 0.9rem;
        line-height: 0.9rem;
        background: #f4cc25;
        border-radius: 0.08rem;
        border: 1px solid #000000;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
      }
    }
    .text-policy {
      height: 26px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 26px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      i {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-n.png") no-repeat center;
        background-size: 100%;
        margin-right: 5px;
        cursor: pointer;
        &.active {
          background: url("../assets/img/create/icon-y.png") no-repeat center;
          background-size: 100%;
        }
      }
      span {
        color: #000;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }
}
</style>
