<template>
  <div class="page-logon">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <van-form @submit="onSubmit" class="form-box">
          <van-field
            v-model="email"
            name="email"
            label="Email*"
            placeholder="Email*"
            :rules="[{ required: true, message: 'Please enter email' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="password"
            label="Confirm Password*"
            placeholder="Confirm Password*"
            :rules="[{ required: true, message: 'Please enter password' }]"
          />
          <van-button class="sb-bt" round block type="info" native-type="submit"
            >LOG IN</van-button
          >
        </van-form>
        <div class="order-type">
          <p class="text-sign">
            New Lucky Member?
            <span @click="goPageByName({ name: 'create' })">Sign up </span
            ><br />
            <span @click="onForgot">Forgot password?</span>
          </p>
          <!-- <p class="text">Or log on with your social media account</p>
          <div class="bt-box">
            <button class="bt-fb"><i></i><span>Facebook</span></button>
            <button class="bt-gg"><i></i><span>Google</span></button>
          </div>
          <p class="tips">
            By clicking any of the social login buttons you agree to the terms
            of privacy policy described <span>here</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loginByEmailPwd, forgetPwd } from "@/api/user";
import { goPageByName, goReplaceByName } from "@/util/link";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "LogonView",
  data() {
    return {
      email: "",
      password: "",
      redireUrl: "",
      redireName: "",
    };
  },
  components: {},
  computed: {
    ...mapState(["isLogin"]),
  },
  mounted() {
    if (this.isLogin) {
      goPageByName({
        name: "membership",
      });
    }
    console.log("query", this.$route);
    this.redireUrl = this.$route.query.redireUrl;
    this.redireName = this.$route.query.redireName;
  },
  methods: {
    async onSubmit() {
      const res = await loginByEmailPwd({
        email: this.email,
        password: this.password,
      });
      if (res.code === 0) {
        window.localStorage.setItem("authToken", res.data.authToken);
        window.localStorage.setItem("cardNo", res.data.cardNo);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("userId", res.data.userId);
        store.commit("setLoginState", true);
        if (this.redireUrl) {
          window.location.replace(this.redireUrl);
        } else if (this.redireName == "membership") {
          goReplaceByName({ name: this.redireName });
        } else {
          goReplaceByName({ name: "home" });
        }
      } else {
        this.$toast(res.msg);
      }
    },
    goPageByName({ name }) {
      goPageByName({ name });
    },
    async onForgot() {
      if (!/@.*/.test(this.email)) {
        this.$toast("Please enter the correct Email Address");
      } else {
        const res = await forgetPwd({
          email: this.email,
        });
        if (res.code === 0) {
          this.$toast("Password reset link sent to your email successfully!");
        } else {
          this.$toast(res.msg);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-logon {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/logon/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 100%;
      padding: 0.5rem 0.3rem 1rem;
      box-sizing: border-box;
      text-align: left;
    }
    .form-box::v-deep {
      .van-field__label {
        display: none;
      }
      .van-cell {
        padding: 0;
        box-sizing: border-box;
        // height: 0.88rem;
        margin-bottom: 0.5rem;
        overflow: visible;
        background-color: #f4f5f6;
      }
      .van-field--error .van-field__control {
        border: 1px solid #ff0000;
      }
      .van-field__control {
        height: 0.88rem;
        padding: 0.3rem 0 0.3rem 0.3rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        box-sizing: border-box;
        border-radius: 0.08rem;
        border: 1px solid #cdcdcd;
      }
      .van-field__error-message {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 0.26rem;
        margin-top: 0.2rem;
        padding-left: 0.3rem;
      }
      .van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: #666;
      }
      .sb-bt {
        width: 6.9rem;
        height: 0.9rem;
        line-height: 0.88rem;
        background: #f4cc25;
        border-radius: 0.08rem;
        border: 1px solid #000000;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
      }
    }
    .order-type {
      padding-top: 0.3rem;
      .text-sign {
        text-align: center;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        line-height: 0.38rem;
        // padding-bottom: 0.5rem;
        // border-bottom: 1px dashed #a7a7a8;
        // margin-bottom: 0.5rem;
        span {
          color: #ff521f;
        }
      }
      .text {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        line-height: 0.38rem;
        margin-bottom: 0.3rem;
      }
      .bt-box {
        button {
          width: 100%;
          height: 0.9rem;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #979797;
          font-size: 0.28rem;
          font-family: AlibabaPuHuiTiM;
          color: #000000;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0.3rem;
          i {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.2rem;
          }
          &.bt-fb i {
            background: url("../assets/img/logon/icon-fb.png") no-repeat center;
            background-size: 100%;
          }
          &.bt-gg i {
            background: url("../assets/img/logon/icon-gg.png") no-repeat center;
            background-size: 100%;
          }
        }
      }
      .tips {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        line-height: 0.36rem;
        span {
          color: #1977f2;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
