<template>
  <div class="page-points">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- list -->
    <div class="section-detail section-box">
      <div class="content-box">
        <p class="title">Lucky Points: {{ points }}</p>
        <div class="header">
          <span class="source">Source</span>
          <span class="points">Points</span>
          <span class="total">Total</span>
          <span class="time">Time</span>
        </div>
        <div class="points-detail">
          <template v-if="listData.length">
            <div
              class="points-item"
              v-for="(item, index) in listData"
              :key="index"
            >
              <span class="source">{{ item.source }}</span>
              <span class="points">{{ item.trans }}</span>
              <span class="total">{{ item.total }}</span>
              <span class="time">{{ item.createdAt }}</span>
            </div>
          </template>
          <div class="points-empty" v-if="!listData.length">
            <div class="img-box">
              <img src="../assets/img/points/icon-empty.png" alt="" />
            </div>
            <p>It’s empty here :-(</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPointsLog } from "@/api/points";
export default {
  name: "PointsView",
  data() {
    return {
      listData: [],
      points: "",
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    this.getPointsLog();
  },
  methods: {
    async getPointsLog() {
      const res = await getPointsLog();
      if (res.code === 0) {
        this.listData = res.data.list;
        this.points = res.data.points;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-points {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/points/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-detail {
    background: #f4f5f6;
    padding: 0.5rem 0.3rem 1rem;
    box-sizing: border-box;
    .content-box {
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0.3rem 0.3rem 0.5rem;
      background: #fff;
      text-align: left;
    }
    .title {
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiB;
      color: #000000;
      line-height: 0.38rem;
      margin-bottom: 0.3rem;
    }
    .header {
      padding: 0.29rem 0;
      border-top: 1px dashed #a7a7a8;
      border-bottom: 1px dashed #a7a7a8;
      span {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiB;
        color: #000000;
        line-height: 0.42rem;
        display: inline-block;
      }
      .source {
        width: 2.11rem;
      }
      .points {
        width: 1.18rem;
      }
      .total {
        width: 2.25rem;
      }
    }
    .points-detail {
      .points-item {
        margin-top: 0.3rem;
        display: flex;
      }
      span {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.42rem;
        display: inline-block;
      }
      .source {
        width: 2.55rem;
      }
      .points {
        width: 0.71rem;
      }
      .total {
        width: 0.71rem;
        text-align: right;
      }
      .time {
        flex: 1;
        text-align: right;
      }
    }
    .points-empty {
      margin-top: 1.16rem;
      margin-bottom: 0.66rem;
      text-align: center;
      .img-box {
        width: 1.54rem;
        height: 1.26rem;
        margin: 0 auto;
      }
      p {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.34rem;
        margin-top: 0.27rem;
      }
    }
  }
}
</style>
