<template>
  <div class="banner">
    <div class="content-box">
      <van-swipe ref="swipe" :autoplay="autoplay" indicator-color="white">
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img class="img" :src="item.image" alt="" />
        </van-swipe-item>
      </van-swipe>
      <img class="bg-banner" src="../assets/img/home/bg-banner.png" alt="" />
      <div v-if="banner.length > 1" @click="prev" class="prev"></div>
      <div v-if="banner.length > 1" @click="next" class="next"></div>
    </div>
  </div>
</template>

<script>
import banner from "../assets/img/home/banner-1.jpg";
export default {
  name: "LkBt",
  props: {
    banner: {
      type: Array,
      default: () => [{ image: banner }],
    },
  },
  watch: {
    banner(newval) {
      if (newval.length > 1) this.autoplay = 3000;
    },
  },
  data() {
    return {
      autoplay: 0,
    };
  },
  mounted() {},
  methods: {
    submit() {
      this.$emit("submit");
    },
    prev() {
      console.log(this.$refs.swipe);
      this.$refs.swipe.prev();
    },
    next() {
      this.$refs.swipe.next();
    },
  },
};
</script>
<style lang="scss" scoped>
.banner::v-deep {
  width: 100%;
  position: relative;
  .content-box {
    font-size: 0;
    position: relative;
  }
  .bg-banner {
    width: 100%;
    height: 4.24rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .img {
    width: 100%;
    height: 4.24rem;
  }
  .prev {
    width: 0.72rem;
    height: 0.6rem;
    background: url("../assets/img/home/icon-winner-l.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0.35rem;
    right: 1.47rem;
    cursor: pointer;
    z-index: 99;
    &:active {
      background: url("../assets/img/home/icon-winner-l-a.png") no-repeat center
        bottom;
      background-size: 100% 0.53rem;
    }
  }
  .next {
    width: 0.72rem;
    height: 0.6rem;
    background: url("../assets/img/home/icon-winner-r.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0.35rem;
    right: 0.5rem;
    cursor: pointer;
    z-index: 99;
    &:active {
      background: url("../assets/img/home/icon-winner-r-a.png") no-repeat center
        bottom;
      background-size: 100% 0.53rem;
    }
  }
}
</style>
