import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/style.css";
// import { Collapse, CollapseItem, } from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import { Locale } from "vant";
// 引入英文语言包
import enUS from "vant/es/locale/lang/en-US";
import { goPageByName } from "./util/link";

Locale.use("en-US", enUS);

import "vant/lib/popup/style";
import "vant/lib/collapse/style";
import "vant/lib/collapse-item/style";
import "vant/lib/swipe/style";
import "vant/lib/switch-cell/style";
import "vant/lib/form/style";
import "vant/lib/field/style";
import "vant/lib/button/style";
import "vant/lib/radio/style";
import "vant/lib/radio-group/style";
import "vant/lib/datetime-picker/style";
import "vant/lib/cascader/style";
import "vant/lib/uploader/style";
import "vant/lib/toast/style";

import { Popup } from "vant";
import { Collapse, CollapseItem } from "vant";
import { Swipe, SwipeItem } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { RadioGroup, Radio } from "vant";
import { DatetimePicker } from "vant";
import { Cascader } from "vant";
import { Uploader } from "vant";
import { Toast } from "vant";

Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Cascader);
Vue.use(DatetimePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popup);
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(Collapse);
Vue.use(CollapseItem);

let pathNameArr = [
  "address",
  "redeemhistory",
  "redeemprizes",
  "points",
  "details",
  "membership",
];

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
if (!isMobile) {
  const hash = location.hash || "";
  const url = `${location.protocol}//www.luckybox.au/${hash}`;
  window.location.replace(url);
}

Vue.config.productionTip = false;
router.beforeEach((to, from, nex) => {
  let isGo = false;
  if (!store.state.isLogin) {
    for (let i = 0; i < pathNameArr.length; i++) {
      console.log("to.name", to.name);
      console.log("pathNameArr[i]", pathNameArr[i]);
      if (to.name == pathNameArr[i]) {
        goPageByName({
          name: "logon",
          query: {
            redireName: to.name,
          },
        });
        isGo = true;
      }
    }
    if (!isGo) {
      nex();
    }
  } else {
    nex();
  }
});
router.afterEach((to, from, nex) => {
  let navData = store.state.navData;
  const path = to.path;
  for (let i = 0; i < navData.length; i++) {
    navData[i].isActive = path == navData[i].path;
  }
  store.commit("seveNavData", navData);
  console.log("to", to.path, from, nex);
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
