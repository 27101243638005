<template>
  <div class="page-redeem-prizes">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <p class="label">Redeem Code</p>
        <div class="code-box">
          <input v-model="verifyCode" type="text" />
          <img @click="onChangeCodeImge" :src="redeemCodeImg" alt="" />
        </div>
        <div class="input-box" :class="{ error: redeemCodeError }">
          <input @focus="onFocus" v-model="redeemCode" type="text" />
          <button @click="onApply">Apply</button>
        </div>
        <template v-if="productImg">
          <p class="label">My Prize</p>
          <div class="prize-img img-box">
            <img v-if="productImg" :src="productImg" alt="" />
          </div>
          <p class="prize-text">
            Product：{{ productName }}<br />Price: {{ productPrizes }}
          </p>
        </template>
        <p class="label">Instagram Post</p>
        <p class="post-text no-b">Please upload your Instagram Post</p>
        <p class="post-text">
          (The maximum upload size is 10Mbs accept png/jpg/jpeg format only.)
        </p>
        <van-uploader
          :after-read="afterRead"
          @delete="deleteUpload"
          class="uploader-box"
          v-model="fileList"
          multiple
          :max-count="1"
          :max-size="10000 * 1024"
        />
        <button @click="onNextBt" class="next-bt">NEXT ></button>
      </div>
    </div>
  </div>
</template>
<script>
import { getPrizeInfo } from "@/api/redeem";
import { uploadFile } from "@/api/upload";
import { goPageByName } from "@/util/link";
export default {
  name: "RedeemPrizesView",
  data() {
    return {
      redeemCode: "",
      verifyCode: "",
      redeemCodeImg: "",
      productName: "name",
      productImg: "",
      productPrizes: "0",
      codeId: "",
      fileList: [],
      screenshot: "",
      redeemCodeError: false,
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    this.redeemCode = this.$route.query.redeemCode || "";
    this.onChangeCodeImge();
  },
  methods: {
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await uploadFile(formData);
      if (res.code === 0) {
        this.screenshot = res.data.url;
      } else {
        this.$toast(res.msg);
      }
    },
    deleteUpload() {
      this.screenshot = "";
    },
    onChangeCodeImge() {
      this.redeemCodeImg = `//api.luckybox.au/captcha/v1/create?t=${new Date().getTime()}`;
    },
    async onApply() {
      if (!this.verifyCode) {
        this.$toast("Please enter the verification code");
        return;
      }
      if (!this.redeemCode) {
        this.$toast("Please enter the exchange code");
        return;
      }
      const res = await getPrizeInfo({
        redeemCode: this.redeemCode,
        verifyCode: this.verifyCode,
      });
      if (res.code === 0) {
        this.codeId = res.data.codeId;
        this.productImg = res.data.image;
        this.productPrizes = res.data.price;
        this.productName = res.data.name;
      } else {
        this.onChangeCodeImge();
        this.$toast(res.msg);
        this.redeemCodeError = true;
      }
    },
    onNextBt() {
      if (!this.codeId) {
        this.$toast("Please enter the exchange code");
        return;
      }
      if (!this.screenshot) {
        this.$toast("Please Upload Your Instagram Post");
        return;
      }
      goPageByName({
        name: "address",
        query: {
          codeId: this.codeId,
          screenshot: this.screenshot,
          redeemCode: this.redeemCode,
          type: "redeemprizes",
        },
      });
    },
    onFocus() {
      this.redeemCodeError = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-redeem-prizes {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/redeemprizes/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 100%;
      margin: 0 auto;
      padding: 0.5rem 0.3rem 1rem;
      box-sizing: border-box;
      text-align: left;
    }
    .label {
      font-size: 0.36rem;
      font-family: AlibabaPuHuiTiB;
      color: #000000;
      line-height: 0.5rem;
      margin-bottom: 0.3rem;
    }
    .input-box {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      &.error {
        input {
          border: 1px solid rgba($color: #ff0000, $alpha: 0.5);
        }
      }
      input {
        width: 5rem;
        height: 0.9rem;
        padding: 0.25rem 0 0.25rem 0.2rem;
        font-size: 0.28rem;
        border-radius: 0.08rem;
        border: 1px solid rgba($color: #979797, $alpha: 0.5);
        box-sizing: border-box;
      }
      button {
        width: 1.6rem;
        line-height: 0.9rem;
        background: #000000;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #ffffff;
        box-sizing: border-box;
      }
    }
    .code-box {
      margin-bottom: 0.2rem;
      display: flex;
      justify-content: space-between;
      input {
        width: 5rem;
        height: 0.9rem;
        padding: 0.2rem 0 0.2rem 0.2rem;
        font-size: 0.28rem;
        border-radius: 0.08rem;
        border: 1px solid rgba($color: #979797, $alpha: 0.5);
        box-sizing: border-box;
      }
      img {
        width: 1.6rem;
        line-height: 0.9rem;
        background: #000000;
        border-radius: 0.08rem;
        box-sizing: border-box;
      }
    }
    .prize-img {
      width: 6.9rem;
      height: 6.9rem;
      margin-bottom: 0.28rem;
      border-radius: 0.08rem;
      border: 1px solid rgba($color: #979797, $alpha: 0.5);
      overflow: hidden;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .prize-text {
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 0.42rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px dashed #a7a7a8;
    }
    .post-text {
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 0.42rem;
      margin-bottom: 0.5rem;
      &.no-b {
        margin-bottom: 0rem;
      }
    }
    .next-bt {
      width: 100%;
      line-height: 0.9rem;
      background: #f4cc25;
      border-radius: 0.08rem;
      border: 1px solid #000000;
      box-sizing: border-box;
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      text-align: center;
    }
    .uploader-box::v-deep {
      margin-bottom: 0.5rem;
      .van-uploader__upload {
        width: 3.2rem;
        height: 4.5rem;
        border-radius: 0.08rem;
        border: 1px solid rgba($color: #979797, $alpha: 0.5);
        box-sizing: border-box;
        overflow: hidden;
        margin-right: 0;
      }
      .van-uploader__preview-image {
        width: 100%;
        max-width: 5rem;
        height: 4.5rem;
        border-radius: 0.08rem;
        border: 1px solid rgba($color: #979797, $alpha: 0.5);
        box-sizing: border-box;
      }
      .van-uploader__preview {
        margin-right: 0.5rem;
      }
      .van-image__error,
      .van-image__img,
      .van-image__loading {
        object-fit: contain !important;
      }
      .van-image__img {
        object-fit: cover !important;
      }
      .van-icon-photograph:before,
      .van-uploader__preview-delete-icon {
        display: none;
      }
      .van-uploader__preview-delete {
        width: 0.84rem;
        height: 0.84rem;
        background: url("../assets/img/redeemprizes/icon-x.png") no-repeat
          center;
        background-size: 100%;
        right: 0;
        top: 0;
        transform: translate3d(50%, -50%, 0);
      }
      .van-uploader__upload-icon {
        width: 0.84rem;
        height: 1.23rem;
        background: url("../assets/img/redeemprizes/icon-upload.png") no-repeat
          center;
        background-size: 100%;
      }
    }
  }
}
</style>
