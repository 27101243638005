import Vue from "vue";
import Vuex from "vuex";
import { link } from "@/common/config/index";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navData: [
      { name: "Home", path: link.home, isActive: false },
      { name: "What’s in the box", path: link.box, isActive: false },
      { name: "Membership", path: link.membership, isActive: false },
      { name: "Store locator", path: link.store, isActive: false },
      { name: "About us", path: link.about, isActive: false },
      { name: "News", path: link.news, isActive: false },
    ],
    isLogin: localStorage.getItem("authToken") || false,
  },
  getters: {},
  mutations: {
    seveNavData(state, data) {
      state.navData = data;
    },
    setLoginState(state, data) {
      state.isLogin = data;
    },
    setLogOut(state) {
      state.isLogin = false;
      window.localStorage.clear();
    },
  },
  actions: {},
  modules: {},
});
