<template>
  <div class="section-bottom">
    <div class="content-box">
      <div class="icon-lucky"></div>
      <div class="find-list">
        <div class="title">Find Us On</div>
        <div class="icon-list">
          <!-- <img src="../assets/img/bottom/icon-1.png" alt="" /> -->
          <img @click="goOthers" src="../assets/img/bottom/icon-2.png" alt="" />
          <!-- <img src="../assets/img/bottom/icon-3.png" alt="" />
          <img src="../assets/img/bottom/icon-4.png" alt="" />
          <img src="../assets/img/bottom/icon-5.png" alt="" />
          <img src="../assets/img/bottom/icon-6.png" alt="" />
          <img src="../assets/img/bottom/icon-7.png" alt="" /> -->
        </div>
      </div>
      <div class="cantact-us">
        <p class="title">Contact Us</p>
        <p class="name">
          <a href="mailto:hello@luckybox.au">hello@luckybox.au</a>
        </p>
      </div>
      <van-collapse v-model="activeNames" class="nav-list">
        <van-collapse-item title="Navigation" name="1">
          <router-link class="name" to="/">Home</router-link>
          <router-link class="name" to="/box">What's in the Box</router-link>
          <router-link class="name" to="/membership">Membership</router-link>
          <router-link class="name" to="/store">Store Locator</router-link>
          <router-link class="name" to="/about">About us</router-link>
          <router-link class="name" to="/news"
            >News</router-link
          ></van-collapse-item
        >
        <van-collapse-item title="Help" name="2"
          ><router-link
            class="name"
            :to="{ path: '/help', query: { type: 'faq' } }"
            >FAQ’s</router-link
          >
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'prize' } }"
            >Prize Redeem Policy</router-link
          >
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'privacy' } }"
            >Privacy Policy</router-link
          >
          <router-link
            class="name"
            :to="{ path: '/help', query: { type: 'terms' } }"
            >Terms & condition</router-link
          ></van-collapse-item
        >
      </van-collapse>
      <div class="tips-box">
        <p class="text">
          Copyright © 2022 Lucky Box Australia all rights reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import { getAddress } from "@/api/address";
// import { isAddressTimeOver } from "@/util/index";

export default {
  name: "PageBottom",
  props: {
    msg: String,
  },
  data() {
    return {
      activeNames: [],
    };
  },
  mounted() {
    // if (!window.localStorage.getItem("addressDate") || isAddressTimeOver()) {
    //   this.getAllAddress();
    // }
  },
  methods: {
    goOthers() {
      window.location.href =
        "https://instagram.com/luckyboxaustralia?igshid=YmMyMTA2M2Y=";
    },
    // async getAllAddress() {
    //   let options = [];
    //   const res = await getAddress({}, "state");
    //   options = res.info;
    //   for (let i = 0; i < options.length; i++) {
    //     const r = await getAddress({}, options[i].name);
    //     options[i].children = r.info;
    //   }
    //   window.localStorage.setItem("addressDate", JSON.stringify(options));
    //   const t = new Date().getTime() / 1000 / 60 / 60;
    //   window.localStorage.setItem("AddressTime", t);
    // },
  },
};
</script>

<style lang="scss" scoped>
.section-bottom {
  width: 100%;
  background: #231815;
  .content-box {
    width: 100%;
    padding: 0.5rem 0.3rem 1.38rem;
    box-sizing: border-box;
    position: relative;
  }
  .icon-lucky {
    width: 2.05rem;
    height: 0.54rem;
    background: url("../assets/img/bottom/icon-logo.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
  }
  .cantact-us {
    padding: 0.5rem 0 0.3rem;
    border-bottom: 1px solid #3f3f3f;
    .title {
      font-size: 0.32rem;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
      line-height: 0.44rem;
    }
    .name {
      font-size: 0.26rem;
      font-family: AlibabaPuHuiTiR;
      color: #cdcdcd;
      line-height: 0.34rem;
      margin-top: 0.2rem;
      a:link {
        color: #cdcdcd;
      }
    }
  }
  .find-list {
    margin-top: 0.52rem;
    .title {
      height: 0.44rem;
      font-size: 0.32rem;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
      line-height: 0.44rem;
    }
    .icon-list {
      margin-top: 0.2rem;
      img {
        width: 0.44rem;
        height: 0.44rem;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .tips-box {
    width: 100%;
    text-align: center;
    margin-top: 50px;

    .text {
      height: 0.33rem;
      font-size: 0.24rem;
      font-family: AlibabaPuHuiTiR;
      color: #757575;
      line-height: 0.33rem;
    }
  }
  .nav-list::v-deep {
    .name {
      display: block;
      font-size: 0.26rem;
      font-family: AlibabaPuHuiTiR;
      color: #cdcdcd;
      line-height: 0.34rem;
      margin-bottom: 0.2rem;
    }
    .van-cell {
      line-height: 1.04rem;
      background-color: #231815;
      padding: 0;
      display: flex;
      align-items: center;
    }
    .van-cell__title {
      font-size: 0.32rem;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
    }
    .van-collapse-item__content {
      background-color: #231815;
    }
    .van-cell__right-icon {
      width: 0.48rem;
      height: 0.48rem;
      background: url("../assets/img/bottom/icon-+.png") no-repeat center;
      background-size: 100%;
    }
    .van-collapse-item__title--expanded .van-cell__right-icon {
      width: 0.48rem;
      height: 0.48rem;
      background: url("../assets/img/bottom/icon--.png") no-repeat center;
      background-size: 100%;
    }
    .van-icon-arrow:before {
      display: none;
    }
    .van-collapse-item {
      border-bottom: 1px solid #3f3f3f;
    }
    &::after {
      display: none;
    }
    .van-collapse-item--border::after {
      display: none;
    }
    .van-collapse-item__title--expanded::after {
      display: none;
    }
    .van-collapse-item__content {
      padding: 0 0 0.1rem;
    }
  }
}
</style>
