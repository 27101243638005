<template>
  <div class="page-home">
    <!-- banner -->
    <BannerSwiper :banner="bannerList" class="section-banner"></BannerSwiper>
    <!-- what -->
    <div class="section-what">
      <div class="content-box">
        <h1 class="title">What is Lucky Box?</h1>
        <p class="text">
          The Core Mission of Lucky Box is creating to surprise and delight the
          world! We use our intelligent vending machines to provide players with
          valuable and amazing surprises!
        </p>
        <img class="step" src="../assets/img/home/icon-what-step.png" alt="" />
        <img
          :class="{ totop: isLuckytop }"
          class="icon-lucky"
          src="../assets/img/home/icon-lucky.png"
          alt=""
          @click="onLucky"
        />
        <img
          class="icon-redeem"
          src="../assets/img/home/icon-redeem.png"
          alt=""
          @click="goPageByName('redeemprizes')"
        />
      </div>
    </div>
    <!-- share -->
    <div class="section-share">
      <div class="an-share"></div>
      <div class="content-box">
        <p>Share Your Secret Story On Our Answer Card!</p>
        <div class="ui-lk-bt-box share-bt">
          <button @click="onBtShare" class="ui-lk-bt">Share Now</button>
          <i class="ui-lk-bt-i"></i>
        </div>
        <img
          class="img-left"
          src="../assets/img/home/img-xiaoh-left.png"
          alt=""
        />
        <img
          class="img-right"
          src="../assets/img/home/img-xiaoh-right.png"
          alt=""
        />
      </div>
    </div>
    <!-- winner -->
    <div class="section-winner" id="winner">
      <div class="content-box">
        <h1 class="title">Previous Winners</h1>
        <div class="winner-box">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in winnerList"
                :key="index"
              >
                <div class="share-item">
                  <div class="winner-item">
                    <div class="img-box">
                      <img
                        v-if="index % 3 == 0"
                        class="img-bg"
                        src="../assets/img/home/bg-winner-1.png"
                        alt=""
                      />
                      <img
                        v-if="index % 3 == 1"
                        class="img-bg"
                        src="../assets/img/home/bg-winner-2.png"
                        alt=""
                      />
                      <img
                        v-if="index % 3 == 2"
                        class="img-bg"
                        src="../assets/img/home/bg-winner-3.png"
                        alt=""
                      />
                      <img class="img-user" :src="item.image" alt="" />
                    </div>
                    <p class="name">{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="winner-choose">
          <span v-show="isL" @click="onPrev" class="choose-l"></span>
          <span v-show="isR" @click="onNext" class="choose-r"></span>
        </div>
        <div class="ui-lk-bt-box winner-bt" id="wish">
          <button class="ui-lk-bt" @click="goPageByName('store')">
            FIND A LUCKY BOX VENDING<br />MACHINE NEAR ME
          </button>
          <i class="ui-lk-bt-i"></i>
        </div>
        <i class="icon-line"></i>
        <i class="icon-line-2"></i>
        <p class="text-prizes">
          What Prizes Do You Want To<br />See In Lucky Box? Let Us Know!
        </p>
        <div class="ui-lk-bt-box prizes-bt">
          <button @click="onBtWish" class="ui-lk-bt">I WISH FOR...</button>
          <i class="ui-lk-bt-i"></i>
        </div>
      </div>
    </div>
    <LkDialog ref="LkDialog"></LkDialog>
    <LkDialogForm
      ref="LkDialogForm"
      @submit="onSubmit"
      @close="onClose"
    ></LkDialogForm>
  </div>
</template>

<script>
import BannerSwiper from "@/components/BannerSwiper.vue";
import LkDialogForm from "@/components/LkDialogForm.vue";
import LkDialog from "@/components/LkDialog.vue";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import { submitText, getMaterial } from "@/api/submission";
import { mapState } from "vuex";
import { goPageByName } from "@/util/link";
export default {
  name: "HomeView",
  components: {
    BannerSwiper,
    LkDialogForm,
    LkDialog,
  },
  data() {
    return {
      autoplay: false,
      isLuckytop: false,
      desc: {},
      mySwiper: {},
      bannerList: [],
      winnerList: [],
    };
  },
  computed: {
    ...mapState(["isLogin"]),
    isL() {
      return true;
      // return this.mySwiper.activeIndex > 0;
    },
    isR() {
      return true;
      // return this.mySwiper.activeIndex < 5;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.getMaterial();
    setTimeout(() => {
      this.mySwiper = new Swiper(".swiper-container", {
        autoplay: true,
        loop: true, // 循环模式选项
        slidesPerView: "auto",
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
        observer: true,
        observerParents: true,
      });
    }, 1000);
  },
  methods: {
    onPrev() {
      this.mySwiper.slidePrev();
    },
    onNext() {
      this.mySwiper.slideNext();
    },
    onScroll() {
      // const h = window.screen.height / 2;
      // const t =
      //   document.querySelector(".section-what") &&
      //   document.querySelector(".section-what").offsetTop;
      const scop = document.documentElement.scrollTop;
      this.isLuckytop = scop > 20;
    },
    onBtShare() {
      if (!this.isLogin) {
        goPageByName({
          name: "logon",
          query: {
            redireName: "home",
          },
        });
        return;
      }
      console.log(this.desc);
      this.$refs.LkDialogForm.show({
        title: "Share Your Secret Story on our Answer Card!",
        bt: "Share",
        type: "share",
        desc: this.desc.share,
      });
    },
    onBtWish() {
      if (!this.isLogin) {
        goPageByName({
          name: "logon",
          query: {
            redireName: "home",
          },
        });
        return;
      }
      this.$refs.LkDialogForm.show({
        title: `What Prizes do you want to see in Lucky Box? Let us know!`,
        bt: "I wish for...",
        type: "wish",
        desc: this.desc.wish,
      });
    },
    async onSubmit(obj) {
      console.log("obj", obj);
      if (!obj.desc) {
        this.$toast("Please write some words before submitting.");
        return;
      }
      const type = obj.type === "share" ? 0 : 1;
      const res = await submitText({
        content: obj.desc,
        type,
      });
      if (res.code === 0) {
        this.$refs.LkDialogForm.close();
        this.$refs.LkDialog.show({
          title: `Thanks for your share!`,
          text: "Your information has been submitted successfully.",
          bt: "OK",
        });
      } else {
        this.$toast(res.msg);
      }
    },
    onClose(obj) {
      console.log("obj-c", obj);
      this.desc[obj.type] = obj.desc;
    },
    async getMaterial() {
      const res = await getMaterial({
        tags: "mIndexBanner,previousWinners",
      });
      console.log("res", res);
      this.bannerList = res.data.mIndexBanner;
      this.winnerList = res.data.previousWinners;
    },
    goPageByName(name) {
      goPageByName({
        name,
      });
    },
    onLucky() {
      window.location.href = "#winner";
    },
  },
};
</script>
<style lang="scss" scoped>
.page-home {
  width: 7.5rem;
  overflow: hidden;
  .section-banner::v-deep {
    width: 7.5rem;
    position: relative;
    overflow: hidden;
    // background: #231815;
    &::after {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: line-b-bolang1 12s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -1px;
      left: 100%;
      animation: line-b-bolang2 12s linear infinite;
      z-index: 9;
    }
  }
  .section-what {
    background: #231815;
    margin-top: -1px;
    .content-box {
      width: 100%;
      margin: 0 auto;
      padding: 1.08rem 0 1.02rem;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      .title {
        height: 0.6rem;
        font-size: 0.44rem;
        font-family: AlibabaPuHuiTiH;
        color: #ffffff;
        line-height: 0.6rem;
      }
      .text {
        font-size: 0.26rem;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        line-height: 0.36rem;
        margin-top: 0.31rem;
        padding: 0 0.5rem;
      }
      .step {
        width: 100%;
        height: 2.12rem;
        margin-top: 0.32rem;
      }
      .icon-lucky {
        width: 1.1rem;
        height: 1.1rem;
        position: absolute;
        top: 0.2rem;
        left: 0.23rem;
        animation: anlucky 10s linear infinite;
        transition: all 1s linear;
        z-index: 99;
        &.totop {
          top: -0.82rem;
        }
      }
      .icon-redeem {
        width: 1.1rem;
        height: 1.1rem;
        position: fixed;
        right: 0.42rem;
        top: 4.97rem;
        z-index: 999;
      }
    }
  }
  .section-share {
    width: 100%;
    padding: 1.26rem 0 1.03rem;
    background: linear-gradient(241deg, #edff39 0%, #72fef8 100%);
    text-align: center;
    position: relative;
    &:after {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-lv-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -0.27rem;
      left: 0;
      transform: rotateY(0deg);
      z-index: 99;
    }
    .an-share {
      width: 100%;
      height: 0.36rem;
      position: absolute;
      top: -1px;
      left: 0;
      overflow: hidden;
      z-index: 99;
      &:after {
        width: 9rem;
        height: 0.34rem;
        content: "";
        background: url("../assets/img/home/icon-br-bl.png") repeat center;
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
        animation: bgshare1 12s linear infinite;
      }
      &:before {
        width: 9rem;
        height: 0.34rem;
        content: "";
        background: url("../assets/img/home/icon-br-bl.png") repeat center;
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        animation: bgshare2 12s linear infinite;
      }
    }
    .content-box {
      width: 100%;
      margin: 0 auto;
      position: relative;
    }
    p {
      font-size: 0.44rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.6rem;
      padding: 0 0.8rem;
    }
    .share-bt {
      margin-top: 0.32rem;
    }
    .img-left {
      position: absolute;
      top: -2.5rem;
      right: 0;
      width: 2.02rem;
      height: 2.25rem;
      z-index: 99;
    }
    .img-right {
      position: absolute;
      bottom: -2.05rem;
      left: 0;
      width: 2.46rem;
      height: 3.03rem;
      z-index: 100;
    }
  }
  .section-winner {
    width: 100%;
    height: 17.5rem;
    background: url("../assets/img/home/bg-winner.png") #231815 no-repeat center
      top;
    background-size: 100% 100%;
    position: relative;
    text-align: center;
    overflow: hidden;
    &::after {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -2px;
      left: 0;
      animation: line-b-bolang1 12s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -2px;
      left: 100%;
      animation: line-b-bolang2 12s linear infinite;
      z-index: 9;
    }
    .content-box {
      width: 100%;
      margin: 0 auto;
      padding-top: 1.1rem;
    }
    .title {
      font-size: 0.44rem;
      font-family: AlibabaPuHuiTiH;
      color: #fff;
      line-height: 0.6rem;
    }
    .winner-box::v-deep {
      margin-top: 0.48rem;
      font-size: 0;
      .swiper-slide {
        width: 4.77rem !important;
        margin-right: 0.54rem;
      }
      .winner-item {
        display: inline-block;
        position: relative;
        vertical-align: top;
        &.active {
          margin-top: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .img-box {
          position: relative;
        }
        .img-bg {
          width: 4.77rem;
          height: 5.94rem;
        }
        .img-user {
          width: 3.62rem;
          height: 4.23rem;
          position: absolute;
          top: 0.79rem;
          left: 0.57rem;
          object-fit: cover;
        }
        .name {
          font-size: 0.3rem;
          font-family: AlibabaPuHuiTiM;
          color: #000000;
          line-height: 0.42rem;
          margin-top: 0.25rem;
        }
      }
    }
    .winner-choose {
      margin-top: 0.42rem;
      padding-right: 0.5rem;
      text-align: right;
      span {
        width: 0.71rem;
        height: 0.6rem;
        margin-left: 0.25rem;
        display: inline-block;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.choose-l {
          background: url("../assets/img/home/icon-winner-l.png") no-repeat
            center;
          background-size: 100% 100%;
          &:active {
            background: url("../assets/img/home/icon-winner-l-a.png") no-repeat
              center bottom;
            background-size: 100% 0.54rem;
          }
        }
        &.choose-r {
          background: url("../assets/img/home/icon-winner-r.png") no-repeat
            center;
          background-size: 100% 100%;
          &:active {
            background: url("../assets/img/home/icon-winner-r-a.png") no-repeat
              center bottom;
            background-size: 100% 0.54rem;
          }
        }
      }
    }
    .winner-bt {
      width: 5rem;
      margin-top: 0.5rem;
      .ui-lk-bt {
        color: #ffe33e;
        line-height: 0.32rem;
      }
      .ui-lk-bt-i {
        background: linear-gradient(90deg, #ffa800 0%, #de0900 100%);
      }
    }
    .icon-line {
      width: 100%;
      height: 82px;
      background: url("../assets/img/home/bg-lk-line.png") no-repeat center;
      background-size: 2375px 82px;
      position: absolute;
      top: 1366px;
      left: 0;
      transform: rotateZ(-2deg);
      animation: line1 10s linear infinite;
    }
    .icon-line-2 {
      width: 100%;
      height: 82px;
      background: url("../assets/img/home/bg-lk-line.png") no-repeat center;
      background-size: 2375px 82px;
      position: absolute;
      top: 1316px;
      left: -100%;
      transform: rotateZ(-2deg);
      animation: line2 10s linear infinite;
    }
    .text-prizes {
      margin-top: 2.28rem;
      font-size: 0.4rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.55rem;
      padding: 0 0.24rem;
      word-spacing: -7px;
    }
    .prizes-bt::v-deep {
      width: 3.6rem;
      margin-top: 0.32rem;
      .ui-lk-bt {
        color: #ffe33e;
        width: 3.6rem;
      }
      .ui-lk-bt-i {
        width: 3.6rem;
        background: linear-gradient(90deg, #ffc234 0%, #ff5418 100%);
      }
    }
  }
}

@keyframes bgshare1 {
  0% {
    left: 0;
  }
  100% {
    left: -9rem;
  }
}
@keyframes bgshare2 {
  0% {
    left: 8.9rem;
  }
  100% {
    left: 0;
  }
}

@keyframes line1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
    top: 1418px;
  }
}
@keyframes line2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
    top: 1366px;
  }
}
@keyframes anlucky {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
