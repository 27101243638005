<template>
  <div class="page-address">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <van-form @submit="onSubmit" class="form-box">
          <van-field
            v-model="form.firstName"
            class="part"
            placeholder="First Name"
            :rules="RULES.FirstName"
          />
          <van-field
            v-model="form.lastName"
            class="part"
            placeholder="Last Name*"
            :rules="RULES.LastName"
          />
          <div class="phone-box">
            <van-field
              v-model="form.phone"
              placeholder="Phone Number*"
              :rules="RULES.Phone"
            >
            </van-field>
            <i class="select">+61</i>
          </div>
          <van-field
            v-model="form.email"
            placeholder="Email Address*"
            :rules="RULES.Email"
          />
          <van-field
            v-model="form.addressLine1"
            placeholder="Address Line 1*"
            :rules="RULES.AddressLine1"
          />
          <van-field
            v-model="form.addressLine2"
            placeholder="Address Line 2 (optional)"
          />
          <p
            v-if="!form.state"
            @click="onShowCascader"
            class="click-bt"
            :class="{ error: isStateError }"
          >
            Select City/Suburb*<i></i>
            <span class="tips">Please Select City/Suburb</span>
          </p>
          <p
            v-if="form.state"
            @click="cascadershow = true"
            class="click-bt active"
          >
            {{ form.state }} {{ form.suburb }}<i></i>
          </p>
          <van-field
            v-model="form.zipCode"
            placeholder="Post / Zip Code *"
            :rules="RULES.PostCode"
          />
          <van-button class="sb-bt" round block type="info" native-type="submit"
            >CONFIRM</van-button
          >
        </van-form>
      </div>
    </div>
    <!-- 地址 -->
    <van-popup v-model="cascadershow" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="Select City/Suburb"
        :options="options"
        @close="cascadershow = false"
        @finish="onFinish"
        @change="onChange"
        :field-names="fieldNames"
      />
    </van-popup>
  </div>
</template>
<script>
import { setAddress, myAddress } from "@/api/address";
import { submitRedeem } from "@/api/redeem";
import { goPageByName } from "@/util/link";
import { mapState } from "vuex";
// import { isAddressTimeOver } from "@/util/index";
import RULES from "@/util/rules";

export default {
  name: "AddressView",
  data() {
    return {
      cascaderValue: "",
      cascadershow: false,
      options: [],
      fieldNames: {
        text: "name",
        value: "name",
        children: "children",
      },
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        email: "",
        // 州
        state: "",
        // 郊区
        suburb: "",
        zipCode: "",
      },
      isStateError: false,
      isNeedShowCascader: false,
      screenshot: "",
      codeId: "",
      type: "",
      RULES,
    };
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  components: {},
  watch: {
    options(newval) {
      if (newval.length > 0 && this.isNeedShowCascader) {
        this.cascadershow = true;
        this.$toast.clear();
      }
    },
  },
  mounted() {
    if (!this.isLogin) {
      goPageByName({ name: "logon" });
      return;
    }
    console.log(this.$route.query);
    this.screenshot = this.$route.query.screenshot;
    this.codeId = this.$route.query.codeId;
    this.redeemCode = this.$route.query.redeemCode;
    this.type = this.$route.query.type;
    this.myAddress();
    if (window.AddressData) {
      this.options = window.AddressData;
    } else {
      this.getAllAddress();
    }
  },
  methods: {
    handleChange() {},
    async onSubmit() {
      if (!this.form.state || !this.form.suburb) {
        this.isStateError = true;
        return;
      }
      const res = await setAddress(this.form);
      if (res.code === 0) {
        if (this.type === "redeemprizes") {
          this.submitRedeem();
        } else {
          this.$toast("success");
        }
      } else {
        this.$toast(res.msg);
      }
    },
    onFinish(value) {
      this.cascadershow = false;
      this.isStateError = false;
      this.form.state = value.selectedOptions[0].name;
      this.form.suburb = value.selectedOptions[1].name;
    },
    onChange() {},
    async submitRedeem() {
      const data = this.form;
      data.screenshot = this.screenshot;
      data.codeId = this.codeId;
      data.redeemCode = this.redeemCode;
      const res = await submitRedeem(data);
      if (res.code === 0) {
        goPageByName({ name: "remeemsuccess" });
      } else {
        this.$toast(res.msg);
      }
    },
    getAllAddress() {
      setTimeout(() => {
        if (window.AddressData) {
          this.options = window.AddressData;
        } else {
          this.getAllAddress();
        }
      }, 1000);
    },
    onShowCascader() {
      if (!this.options.length) {
        this.isNeedShowCascader = true;
        this.$toast.loading({
          message: "Loading...",
          forbidClick: true,
          duration: 0,
        });
      } else {
        this.cascadershow = true;
      }
    },
    async myAddress() {
      const res = await myAddress();
      if (res.code === 0) {
        console.log(res);
        this.form = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-address {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/address/banner.png") no-repeat center;
    background-size: 100%;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 100%;
      padding: 0.5rem 0.3rem 1rem;
      box-sizing: border-box;
    }
    .form-box::v-deep {
      .van-cell {
        padding: 0;
        box-sizing: border-box;
        // height: 0.88rem;
        margin-bottom: 0.5rem;
        overflow: visible;
        background-color: #f4f5f6;
        vertical-align: top;
      }
      .part {
        width: 3.35rem;
        display: inline-block;
        &:first-child {
          margin-right: 0.2rem;
        }
      }
      .van-field--error .van-field__control {
        border: 1px solid #ff0000;
      }
      .van-field__control {
        height: 0.88rem;
        padding: 0.3rem 0 0.3rem 0.3rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        box-sizing: border-box;
        border-radius: 0.08rem;
        border: 1px solid #cdcdcd;
      }
      .van-field__error-message {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 0.26rem;
        margin-top: 0.2rem;
        padding-left: 0.3rem;
      }
      .van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: #666;
      }
      .van-field__control::placeholder {
        color: #666;
      }
      .label {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiB;
        color: #000000;
        line-height: 0.38rem;
        text-align: left;
        margin-bottom: 0.3rem;
      }
      .van-radio-group--horizontal {
        margin-bottom: 0.5rem;
        justify-content: space-between;
      }
      .van-radio__label {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.38rem;
        margin-left: 0.2rem;
      }
      .van-radio {
        margin-right: 0;
      }
      .van-icon:before {
        display: none;
      }
      .van-icon {
        width: 0.34rem;
        height: 0.34rem;
        background: url("../assets/img/address/icon-k.png") no-repeat center;
        background-size: 100%;
        border: none;
      }
      .van-radio__icon--checked .van-icon {
        width: 0.34rem;
        height: 0.34rem;
        background: url("../assets/img/address/icon-x.png") no-repeat center;
        background-size: 100%;
      }
      .click-bt {
        width: 100%;
        height: 0.9rem;
        line-height: 0.9rem;
        border-radius: 0.08rem;
        border: 1px solid #cdcdcd;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        padding: 0 0.3rem;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        margin-bottom: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        &.active {
          color: #000;
        }
        &.error {
          border: 1px solid #ff0000;
          .tips {
            display: block;
          }
        }
        .tips {
          font-size: 0.24rem;
          font-family: AlibabaPuHuiTiR;
          color: #ff0000;
          line-height: 0.26rem;
          position: absolute;
          left: 0.3rem;
          bottom: -0.4rem;
          display: none;
        }
        i {
          width: 0.22rem;
          height: 0.12rem;
          background: url("../assets/img/address/icon-san.png") no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 0.31rem;
          transform: translate3d(0, -50%, 0);
        }
      }
      .phone-box {
        position: relative;
        .van-cell {
          padding-left: 1rem;
          // padding: 0.3rem 0 0.3rem 0.3rem;
          height: 0.88rem;
          border-radius: 0.08rem;
          border: 1px solid #cdcdcd;
        }
        .van-field--error {
          margin-bottom: 1.22rem;
          border: 1px solid #ff0000;
        }
        .van-field__control {
          padding-left: 0;
          border: 0;
        }
      }
      .select {
        width: 0.5rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000;
        line-height: 0.38rem;
        position: absolute;
        left: 0.3rem;
        top: 0.26rem;
      }
      .sb-bt {
        width: 6.9rem;
        height: 0.9rem;
        line-height: 0.9rem;
        background: #f4cc25;
        border-radius: 0.08rem;
        border: 1px solid #000000;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
      }
    }
    .text-policy {
      height: 26px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 26px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      i {
        width: 18px;
        height: 18px;
        background: url("../assets/img/create/icon-n.png") no-repeat center;
        background-size: 100%;
        margin-right: 5px;
        cursor: pointer;
        &.active {
          background: url("../assets/img/create/icon-y.png") no-repeat center;
          background-size: 100%;
        }
      }
      span {
        color: #000;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }
}
</style>
