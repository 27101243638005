<template>
  <div class="page-reset">
    <!-- banner -->
    <div class="section-banner section-box"></div>
    <!-- form -->
    <div class="section-form section-box">
      <div class="content-box">
        <van-form @submit="onSubmit" class="form-box">
          <van-field
            v-model="form.password"
            placeholder="New password*"
            :rules="RULES.Password"
            type="password"
          />
          <van-field
            v-model="form.repassword"
            placeholder="Confirm Password*"
            :rules="[
              {
                validator: validatorRepassword,
                message: 'password does not match, please retry',
              },
            ]"
            type="password"
          />
          <van-button class="sb-bt" round block type="info" native-type="submit"
            >RESET PASSWORD</van-button
          >
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { resetPwd } from "@/api/user";
import { goPageByName } from "@/util/link";
import store from "@/store";
import RULES from "@/util/rules";

export default {
  name: "ResetView",
  data() {
    return {
      form: {
        password: "",
        repassword: "",
        resetToken: "",
      },
      RULES,
    };
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
    const { resetToken } = this.$route.query;
    this.resetToken = resetToken;
  },
  methods: {
    handleChange() {},
    onSubmit() {
      if (this.form.password !== this.form.repassword) {
        this.$toast("Please enter the same password");
        return;
      }
      this.resetPwd();
    },
    // 注册
    async resetPwd() {
      const res = await resetPwd({
        resetToken: this.resetToken,
        newPassword: this.form.password,
      });
      if (res.code === 0) {
        window.localStorage.setItem("authToken", res.data.authToken);
        window.localStorage.setItem("cardNo", res.data.cardNo);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("userId", res.data.userId);
        store.commit("setLoginState", true);
        this.goPageByName("membership");
      } else {
        this.$toast(res.msg);
      }
    },
    goPageByName(name, type) {
      goPageByName({
        name,
        query: {
          type,
        },
      });
    },
    validatorRepassword(val) {
      return val === this.form.password;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-reset {
  width: 100%;
  .section-banner {
    width: 100%;
    height: 3rem;
    position: relative;
    background: url("../assets/img/reset/banner.jpg") no-repeat center;
    background-size: 100%;
  }
  .section-form {
    background: #f4f5f6;
    .content-box {
      width: 100%;
      padding: 0.5rem 0.3rem 1rem;
      box-sizing: border-box;
    }
    .form-box::v-deep {
      .van-cell {
        padding: 0;
        box-sizing: border-box;
        // height: 0.88rem;
        margin-bottom: 0.3rem;
        overflow: visible;
        background-color: #f4f5f6;
        vertical-align: top;
      }
      .part {
        width: 3.35rem;
        display: inline-block;
        &:first-child {
          margin-right: 0.2rem;
        }
      }
      .van-field--error .van-field__control {
        border: 1px solid #ff0000;
      }
      .van-field__control {
        height: 0.88rem;
        padding: 0.3rem 0 0.3rem 0.3rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        box-sizing: border-box;
        border-radius: 0.08rem;
        border: 1px solid #cdcdcd;
      }
      .van-field__error-message {
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTiR;
        color: #ff0000;
        line-height: 0.26rem;
        margin-top: 0.2rem;
        padding-left: 0.3rem;
      }
      .van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: #666;
      }
      .van-field__control::placeholder {
        color: #666;
      }
      .label {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiB;
        color: #000000;
        line-height: 0.38rem;
        text-align: left;
        margin-bottom: 0.2rem;
      }
      .van-radio-group--horizontal {
        margin-bottom: 0.5rem;
        justify-content: space-between;
      }
      .van-radio__label {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 0.38rem;
        margin-left: 0.2rem;
      }
      .van-radio {
        margin-right: 0;
      }
      .van-icon:before {
        display: none;
      }
      .van-icon {
        width: 0.34rem;
        height: 0.34rem;
        background: url("../assets/img/address/icon-k.png") no-repeat center;
        background-size: 100%;
        border: none;
      }
      .van-radio__icon--checked .van-icon {
        width: 0.34rem;
        height: 0.34rem;
        background: url("../assets/img/address/icon-x.png") no-repeat center;
        background-size: 100%;
      }
      .click-bt {
        width: 100%;
        height: 0.9rem;
        border-radius: 8px;
        border: 1px solid #cdcdcd;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        padding: 0.25rem 0 0.25rem 0.3rem;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        margin-bottom: 0.3rem;
        &.choose {
          color: #000;
        }
        i {
          width: 0.22rem;
          height: 0.12rem;
          background: url("../assets/img/address/icon-san.png") no-repeat center;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 0.31rem;
          transform: translate3d(0, -50%, 0);
        }
      }
      .phone-box {
        position: relative;
        .van-cell {
          padding-left: 1rem;
          // padding: 0.3rem 0 0.3rem 0.3rem;
          height: 0.88rem;
          border-radius: 0.08rem;
          border: 1px solid #cdcdcd;
        }
        .van-field--error {
          margin-bottom: 1.22rem;
          border: 1px solid #ff0000;
        }
        .van-field__control {
          padding-left: 0;
          border: 0;
        }
      }
      .select {
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        line-height: 0.38rem;
        position: absolute;
        left: 0.3rem;
        top: 0.26rem;
      }
      .sb-bt {
        width: 6.9rem;
        height: 0.9rem;
        line-height: 0.9rem;
        background: #f4cc25;
        border-radius: 0.08rem;
        border: 1px solid #000000;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        margin-top: 0.2rem;
      }
    }
  }
}
</style>
