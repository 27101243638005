import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import BoxView from "../views/BoxView.vue";
import HelpView from "../views/HelpView.vue";
import NewsView from "../views/NewsView.vue";
import AboutView from "../views/AboutView.vue";
import StoreView from "../views/StoreView.vue";
import LogonView from "../views/LogonView.vue";
import CreateView from "../views/CreateView.vue";
import AddressView from "../views/AddressView.vue";
// import BasicinfoView from "../views/BasicinfoView.vue";
import RedeemPrizesView from "../views/RedeemPrizesView.vue";
import RedeemHistoryView from "../views/RedeemHistoryView.vue";
import PointsView from "../views/PointsView.vue";
import MemberShipView from "../views/MemberShipView.vue";
import RemeemSuccessView from "../views/RemeemSuccessView.vue";
import DetailsView from "../views/DetailsView.vue";
import ResetView from "../views/ResetView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/box",
    name: "box",
    component: BoxView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/news",
    name: "news",
    component: NewsView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/store",
    name: "store",
    component: StoreView,
  },
  {
    path: "/logon",
    name: "logon",
    component: LogonView,
  },
  {
    path: "/create",
    name: "create",
    component: CreateView,
  },
  {
    path: "/address",
    name: "address",
    component: AddressView,
  },
  {
    path: "/redeemprizes",
    name: "redeemprizes",
    component: RedeemPrizesView,
  },
  {
    path: "/redeemhistory",
    name: "redeemhistory",
    component: RedeemHistoryView,
  },
  {
    path: "/points",
    name: "points",
    component: PointsView,
  },
  {
    path: "/membership",
    name: "membership",
    component: MemberShipView,
  },
  {
    path: "/remeemsuccess",
    name: "remeemsuccess",
    component: RemeemSuccessView,
  },
  {
    path: "/details",
    name: "details",
    component: DetailsView,
  },
  {
    path: "/reset",
    name: "reset",
    component: ResetView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
