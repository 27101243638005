<template>
  <div class="page-remeem-success">
    <!-- success -->
    <div class="section-success section-box">
      <div class="content-box">
        <div class="img-box">
          <img src="../assets/img/remeemsuccess/icon-success.png" alt="" />
        </div>
        <h1 class="title">Success!</h1>
        <p class="text">
          The Lucky Box Team will review the information provided and upon
          confirmation will ship out the prize within 7 working days.
        </p>
        <!-- <p class="text">
          You will automatically receive updates to shipping via email!
        </p> -->
        <p class="text">Thank you for your support!</p>
        <button class="bt-my" @click="goPageByName('redeemhistory')">
          MY PRIZES
        </button>
        <button class="bt-home" @click="goPageByName('home')">HOME</button>
      </div>
    </div>
  </div>
</template>
<script>
import { goPageByName } from "@/util/link";
export default {
  name: "RemeemSuccessView",
  data() {
    return {};
  },
  components: {},
  mounted() {
    console.log(this.$route.query);
  },
  methods: {
    goPageByName(name) {
      goPageByName({ name });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-remeem-success {
  width: 100%;
  background: #f4f5f6;
  .section-success {
    .content-box {
      padding: 0.5rem 0 1rem;
    }
    .img-box {
      width: 4.26rem;
      height: 2.4rem;
      margin: 0 auto;
    }
    .title {
      font-size: 0.36rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.5rem;
      margin-top: 0.5rem;
    }
    .text {
      width: 6.85rem;
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTiM;
      color: #000000;
      line-height: 0.34rem;
      margin: 0.3rem auto 0;
      letter-spacing: 0px;
      word-spacing: -3px;
    }
  }
  .bt-my {
    width: 5.9rem;
    height: 0.9rem;
    line-height: 0.9rem;
    background: #f4cc25;
    border-radius: 8px;
    border: 1px solid #000000;
    box-sizing: border-box;
    font-size: 0.28rem;
    font-family: AlibabaPuHuiTiM;
    color: #000000;
    margin-top: 0.5rem;
  }
  .bt-home {
    width: 5.9rem;
    height: 0.9rem;
    line-height: 0.9rem;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #000000;
    box-sizing: border-box;
    font-size: 0.28rem;
    font-family: AlibabaPuHuiTiM;
    color: #000000;
    margin-top: 0.28rem;
  }
}
</style>
