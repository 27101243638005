<template>
  <van-popup class="lk-dialog" v-model="dialogVisible" closeable @close="close">
    <h1 class="title" v-html="title"></h1>
    <div class="textarea-box">
      <van-field v-model="desc" type="textarea" />
    </div>
    <button @click="submit" class="bt">{{ bt }}</button>
  </van-popup>
</template>

<script>
export default {
  name: "LkAlert",
  components: {},
  data() {
    return {
      title: "Thanks for your share!",
      bt: "share",
      dialogVisible: false,
      desc: "",
      type: "",
    };
  },
  methods: {
    show({ title, bt, type, desc }) {
      this.title = title || this.title;
      this.bt = bt || this.bt;
      this.type = type;
      this.desc = desc;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.$emit("close", { type: this.type, desc: this.desc });
    },
    submit() {
      this.$emit("submit", { type: this.type, desc: this.desc });
      // this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.lk-dialog::v-deep {
  width: 6rem;
  box-sizing: border-box;
  text-align: center;
  padding: 0.78rem 0.5rem 0.77rem;
  .title {
    font-size: 0.36rem;
    font-family: AlibabaPuHuiTiH;
    color: #000000;
    line-height: 0.5rem;
    margin-bottom: 0.3rem;
    word-spacing: -2px;
  }
  .textarea-box {
    width: 5rem;
    height: 3.4rem;
    border-radius: 0.08rem;
    border: 1px solid #000000;
    box-sizing: border-box;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }
  .van-cell,
  .van-field__body,
  .van-field__control {
    padding: 0;
    height: 100%;
    line-height: 1.5;
  }
  .bt {
    width: 2.8rem;
    line-height: 0.88rem;
    background: #f4cc25;
    border-radius: 0.08rem;
    border: 1px solid #000000;
    font-size: 0.28rem;
    font-family: AlibabaPuHuiTiM;
    color: #000000;
  }
  .van-icon-cross:before {
    display: none;
  }
  .van-popup__close-icon {
    width: 0.58rem;
    height: 0.58rem;
    background: url("../assets/img/head/icon-x.png") no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
</style>
