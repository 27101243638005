<template>
  <div class="page-luckybox">
    <!-- banner -->
    <BannerSwiper :banner="bannerList" class="section-banner"></BannerSwiper>
    <div class="lucky-line"></div>
    <!-- play -->
    <div class="section-play">
      <div class="content-box">
        <div class="info-box">
          <van-swipe ref="swipe" :autoplay="0" indicator-color="white">
            <van-swipe-item v-for="(item, index) in playList" :key="index">
              <img class="img" :src="item.image" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <!-- surprises -->
    <div class="section-surprises section-box">
      <div class="content-box">
        <h1 class="title">Let Lucky Box bring you daily surprises!</h1>
        <p class="text">
          Lucky Box will give you a piece of surprise and joyful experience in
          your daily life!
        </p>
        <p class="text text-h">
          Are you having a happy day? feeling lucky? feeling down? is it a
          birthday? an anniversary day?
        </p>
        <p class="tips">TEST YOUR LUCKY DAY!</p>
        <div class="img-day">
          <img src="../assets/img/box/img-day.png" alt="" />
        </div>
      </div>
    </div>
    <!-- prize -->
    <div class="section-prize section-box">
      <div class="content-box">
        <i class="icon-star"></i>
        <p class="title-prize">Redeem My Prize</p>
        <LkBt class="redeem-bt" :msg="'Redeem'" @submit="onRedeemBt"></LkBt>
        <p class="title-find">Find a Lucky Box Vending<br />Machine Near Me</p>
        <LkBt class="go-bt" :msg="'GO'" @submit="onGoBt"></LkBt>
      </div>
    </div>
  </div>
</template>
<script>
import LkBt from "../components/LkBt.vue";
import BannerSwiper from "@/components/BannerSwiper.vue";
import { goPageByName } from "@/util/link";
import { mapState } from "vuex";
import { getMaterial } from "@/api/submission";

export default {
  name: "BoxView",
  data() {
    return {
      autoplay: false,
      bannerList: [],
      playList: [],
    };
  },
  components: {
    LkBt,
    BannerSwiper,
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  mounted() {
    console.log(this.$route.query);
    this.getMaterial();
  },
  methods: {
    onRedeemBt() {
      if (this.isLogin) {
        goPageByName({ name: "redeemprizes" });
      } else {
        goPageByName({ name: "logon" });
      }
    },
    onGoBt() {
      goPageByName({ name: "store" });
    },
    async getMaterial() {
      const res = await getMaterial({
        tags: "mWhatInBoxBanner,howToPlay",
      });
      console.log("res", res);
      this.bannerList = res.data.mWhatInBoxBanner;
      this.playList = res.data.howToPlay;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-luckybox {
  width: 7.5rem;
  overflow: hidden;
  .section-banner {
    width: 100%;
    position: relative;
    .content-box {
      font-size: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .lucky-line {
    width: 100%;
    height: 0.6rem;
    background: url("../assets/img/home/bg-lk-line.png") no-repeat center;
    background-size: 13.09rem 0.6rem;
  }
  .section-play {
    width: 100%;
    height: 11.2rem;
    position: relative;
    background: url("../assets/img/box/bg-play.png") no-repeat center;
    background-size: 100%;
    &::after {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: line-b-bolang1 12s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -1px;
      left: 100%;
      animation: line-b-bolang2 12s linear infinite;
      z-index: 9;
    }
    .content-box {
      width: 100%;
      margin: 0 auto;
      padding: 0 0 0.97rem;
      text-align: center;
      .img-step {
        width: 100%;
        height: 5.26rem;
      }
      .info-box::v-deep {
        width: 6.54rem;
        height: 4.19rem;
        margin: 0 auto;
        padding-top: 6.6rem;
        .img {
          width: 6.54rem;
          height: 4.19rem;
          object-fit: cover;
        }
      }
    }
  }
  .section-surprises {
    background: #231815;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 1;
    .content-box {
      padding-top: 0.75rem;
      padding-bottom: 1.22rem;
    }
    .title {
      font-size: 0.44rem;
      font-family: AlibabaPuHuiTiH;
      color: #ffffff;
      line-height: 0.6rem;
      padding: 0 0.35rem;
    }
    .text {
      font-size: 0.26rem;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 0.36rem;
      margin-top: 0.35rem;
      padding: 0 0.5rem;
      &.text-h {
        margin-top: 0.13rem;
      }
    }
    .tips {
      font-size: 0.26rem;
      font-family: AlibabaPuHuiTiB;
      color: #ffffff;
      line-height: 0.36rem;
      margin-top: 0.4rem;
    }
    .img-day {
      width: 100%;
      height: 2.12rem;
      margin: 0.48rem auto 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .section-prize {
    width: 100%;
    height: 9.5rem;
    background: url("../assets/img/box/bg-prize.png") no-repeat center top;
    background-size: 100% 100%;
    &::after {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
      animation: line-b-bolang1 12s linear infinite;
      z-index: 9;
    }
    &::before {
      width: 7.5rem;
      height: 0.28rem;
      content: "";
      background: url("../assets/img/home/icon-line-bl.png") no-repeat center
        bottom;
      background-size: 100%;
      position: absolute;
      bottom: -1px;
      left: 100%;
      animation: line-b-bolang2 12s linear infinite;
      z-index: 9;
    }
    .content-box {
      position: relative;
      padding-top: 1.4rem;
    }
    .icon-star {
      width: 0.89rem;
      height: 0.94rem;
      background: url("../assets/img/box/icon-star.png") no-repeat center;
      background-size: 100%;
      position: absolute;
      top: -0.15rem;
      right: 0.5rem;
      z-index: 9;
    }
    .title-prize {
      font-size: 0.44rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.6rem;
    }
    .redeem-bt::v-deep {
      margin-top: 0.32rem;
      .ui-lk-bt {
        color: #55ff52;
      }
      .ui-lk-bt-i {
        background: linear-gradient(90deg, #d8f000 0%, #7aff00 100%);
      }
    }
    .title-find {
      margin-top: 2.59rem;
      font-size: 0.44rem;
      font-family: AlibabaPuHuiTiH;
      color: #000000;
      line-height: 0.6rem;
      word-spacing: -3px;
    }
    .go-bt::v-deep {
      margin-top: 0.32rem;
      .ui-lk-bt {
        color: #ffe33e;
      }
      .ui-lk-bt-i {
        background: linear-gradient(90deg, #ffa800 0%, #de0900 100%);
      }
    }
  }
}
</style>
